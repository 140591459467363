.optionsContainer {
    cursor: pointer;
    right: 0px;
    top: 0px;
  }
  
  .container {
    background-color: #ffffff;
    overflow: auto;
    box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.1);
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: auto;
    padding: 20px;
    border-radius: 5px;
    top: 50px
  }
  
  .container button {
    min-width: 160px;
    border-radius: 0;
    border: none;
    outline: none;
    color: var(--blue);
  }

  .singleContainer {
    display: flex;
    align-self: center;
    align-items: center;
    border: 1px solid var(--grey40);
    border-radius: 20px;
    padding: 8px 10px;
    height: 40px;
    font-size: 15px;
    color: var(--grey80);
    text-transform: capitalize;
  }

  .dropDownContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey40);
    border-radius: 20px;
    margin: 2px 0;
    height: 40px;
    width: 95%;
    color: var(--grey80);
    font-size: 16px;
  }
  
  .selected {
    background-color: var(--navy);
    color: var(--white);
  }
  
  .filterCounterContainer {
    display: flex;
    align-items: center;
    border: 1px solid var(--grey80);
    border-radius: 20px;
    padding: 8px;
    height: 40px;
    background-color: var(--navy);
    color: var(--white);
  }
  
  .filterCounter {
    padding: 2px;
    margin-right: 5px;
    background-color: var(--white);
    color: var(--navy);
    border-radius: 5px;
  }
  
  .groupEventLozengesContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    color: var(--grey80);
    overflow-x: scroll;
    overflow-y: auto;
    white-space: nowrap;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .groupEventLozengesContainer::-webkit-scrollbar {
    display: none;
  }
  
  .groupEventLozengesContainer::after {
    content: '';
    padding-right: 20px !important;
  }
