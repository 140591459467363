/* Challenge Detail */

.challengeDetailContainer {
  width: 100%;
  padding: 25px 40px;
  border-bottom: 2px solid var(--grey8);
}

.description {
  margin: 15px 0;
  width: 100%;
  white-space: pre-wrap;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.button {
  margin-top: 5px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--magenta);
  color: var(--white);
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'OpenSans-Extrabold';
  font-size: 16px;
  width: 40%;
}

.button:hover {
  cursor: pointer;
}

.avatarContainer {
  margin: 15px 0;
}

.avatarContainer:hover {
  cursor: pointer;
}

.badgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background-color: var(--greySilver);
  position: relative;
  top: -90px;
  float: right;
}

.badgeIcon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.leaderboardContainer {
  padding: 25px 40px;
  border-bottom: 2px solid var(--grey8);
}

.seeMoreLink {
  text-align: center;
  color: var(--magenta);
  font-weight: bold;
  margin-top: 5px;
}
