.memberInfoContainer {
  display: flex;
  padding: 20px;
  height: 100%;
}

.memberDetails {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
}

.eagleLeaderInfo {
  flex: 1;
  width: 50%;
}

.infoText {
  margin-bottom: 10px; 
  font-size: 15px;
}

.infoTitle {
  text-align: right;
  margin-right: 5px;
  font-size: 15px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
}

.completed {
  color: var(--green);
  font-size: 15px;
}

.pending{
  color: var(--orange);
  font-size: 15px;
}

.alertBox {
  padding: 15px;
}

.alertText {
  margin: 0;
  margin-bottom: 10px; 
  color: var(--magenta); 
  font-weight: bold;
}

.alertMessage {
  margin: 0;
  color: #555; 
}
