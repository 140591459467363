.container {
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  display: flex;
  flex: 1;
}

.eventHost {
  color: var(--magenta);
  font-style: italic;
}
.container:hover,
.container:active {
  background-color: var(--grey5);
  cursor: pointer;
}

.imgContainer {
  margin-right: 20px;
  width: 90px;
  height: 90px;
  position: relative;
}

.img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.adminIconContainer {
  position: absolute;
  left: 60px;
  bottom: -10px;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonContainer {
  align-self: center;
  display: flex;
  margin-left:auto;
  align-items: center;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .container:last-child {
    margin-bottom: 72px;
  }

  .imgContainer {
    margin-right: 20px;
    width: 70px;
    height: 70px;
    position: relative;
  }

  .img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .adminIconContainer {
    position: absolute;
    left: 55px;
    bottom: 0;
  }
}
