.rootContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  max-width: 1064px;
  z-index: 2;
  overflow-y: scroll;
}

.listItemContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
}

.badgeImg {
  height: 136px;
  width: 136px;
  border-radius: 68px;
}

.listItemDescriptionContainer {
  display: block;
  margin-left: 16px;
}

.listItemName {
  font-family: 'OpenSans-Bold';
  display: block;
}

.listItemDateReceived {
  display: block;
  color: var(--grey80);
}

.listItemSeparator {
  border-bottom: 1px solid;
  border-bottom-color: var(--grey8);
  margin-left: 40px;
  margin-right: 40px;
}

@media only screen and (min-width: 768px) {
  .rootContainer {
    transform: translateX(-363px);
  }
}

@media only screen and (max-width: 768px) {
  .listItemContainer {
    margin-left: 0;
    margin-right: 0;
  }
}
