.checkInButton {
    padding: 10px;
    margin-right: 30px;
    font-weight: bold;
    color: var(--magenta);
}

.checkInButton:hover {
    cursor: pointer;
}
