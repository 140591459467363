.workoutCardContainer {
  border: 1px solid #e0e0e1;
  border-radius: 10px;
  padding: 20px;
  position: relative;margin-top: 12px;
}

.workoutInfoContainer {
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 12px;
}

.noBorderCard {
  margin-top: 12px;
  position: relative;
}
.noBorderInfo {
  padding: 0;flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
}

.optionsIconWrapper {
  cursor: pointer;
}

.eventTitle {
  font-size: 20px;
}

.eventDate {
  font-size: 12px;
  font-family: 'OpenSans-Bold';
}

.workoutInfoText {
  font-size: 30px;
  color: '#63666A';
}

.workoutInfoLabelText {
  font-size: 12px;
  font-family: 'OpenSans-Bold';
}

.optionsContainer {
  position: absolute;
  user-select: none;
  top: 0;
  right: 30px;
  top: 20px;
}

.noBorderOptionsContainer {
  display:none
}

.overlayContainer button {
  background-color: #f1f1f1;
  min-width: 160px;
  padding: 12px 16px;
  border-radius: 0;
  border: none;
  border-top: 1px solid var(--grey20);
  outline: none;
}

.overlayContainer {
  position: absolute;
  right: 0px;
  top: 32px;
  background-color: #f1f1f1;
  overflow: auto;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.workoutDescription {
  margin-left: 30px;
  margin-bottom: 20px;
}

.contentContainer {
  display: flex;
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .workoutCardContainer {
    padding-right: 0;
    padding-left: 0;
  }
  .noBorderInfo{
    flex-direction: column;
  }
}
