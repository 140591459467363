.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(-363px);
  background-color: white;
  max-width: 1064px;
  animation: modal 0.5s;
  z-index: 2;
  overflow: hidden;
}

@keyframes modal {
  from {
    top: 100vh;
  }
  to {
    top: 0;
  }
}

.content {
  padding-bottom: 40px;
  overflow: scroll;
  max-height: calc(100vh - 128px);
}

.content textarea {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  overflow: scroll;
}

.shareChallengeBoxWrapper {
  margin: 40px;
  margin-bottom: 0px;
}

.postPhotosContainer {
  position: fixed;
  top: calc(100vh - 270px);
  width: 100%;
}

.attachPhotoContainer {
  position: fixed;
  height: 64px;
  top: calc(100vh - 64px);
  background: var(--magenta);
  width: 100%;
}

.attachPhotoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.attachPhotoText {
  margin-left: 10px;
}

.hideDefaultUpload {
  display: none;
}

.imgPreviewContainer {
  padding: 0px 40px;
  width: 100%;
  max-width: 625px;
  margin: 0 auto;
}

.modalXContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--magenta);
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  right: -15px;
}

.usersListContainer {
  width: 100%;
  max-width: 545px;
  margin: 0 auto;
  border: 1px solid var(--grey80);
  border-radius: 5px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.linkPreviewContainer {
  padding: 0 40px;
}

.carouselButton {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  bottom: 180px;
}

.carouselImgContainer {
  position: relative;
  height: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 24px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.leftCarouselButton {
  transform: rotateY(180deg);
  left: 20px;
}

.rightCarouselButton {
  /* transform: rotateY(180deg); */
  right: 20px;
}

.photoWrapper {
  position: relative;
  margin: 0 10px;
  height: 100%;
  padding-top: 15px;
}

.photoWrapper:first-of-type {
  margin: 0 10px 0 0;
}


/* Responsive */
@media only screen and (max-width: 768px) {
  .container {
    transform: translateX(0);
  }

  .content textarea {
    padding: 0 20px;
  }
}
