/* Login */

.container {
  grid-column: 1/3;
  padding: 0 20%;
}

.logo {
  margin-top: 60px;
  margin-bottom: 50px;
}

.forgotPasswordText {
  margin: 30px 0;
  text-align: center;
}

.socialLoginContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0 30px;
}

.socialLoginIcons {
  margin: 0 10px;
  cursor: pointer;
}
