.container {
  align-items: center;
  margin-bottom: 15px;
  white-space: pre-wrap;
  flex-wrap: wrap;
}

.postText {
  margin-bottom: 15px;
}

.fullName {
  cursor: pointer;
}

.overflowHider {
  overflow: hidden;
  text-overflow: ellipsis;
}
