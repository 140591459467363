/* Text Area */

.container {
  width: 100%;
  position: relative;
  margin: 10px 0;
  padding-top: 25px;
}
/* TODO: Autoresize textarea */
.textArea {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  padding: 0;
  overflow: scroll;
  background-color: transparent;
}
label {
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s ease;
}
.container .textArea:focus ~ label {
  color: black;
}

.characterCount {
  text-align: left;
  margin-right: 10px;
  margin-left: 20px;
}
