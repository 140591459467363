/* Event Creation Choice */

.container {
  position: fixed;
  width: 100%;
  max-width: 1064px;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateX(-363px);
  z-index: 1;
}

.buttonWrapper {
  position: fixed;
  bottom: 40px;
  width: 100%;
  max-width: 625px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
}

.buttonWrapper button:hover {
  opacity: 0.9;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .container {
    transform: translateX(0);
  }
}
