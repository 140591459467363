.instructionsMsg {
  text-align: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-bottom: 100px; /* Due to bottom navigation on mobile devices */
  }
}
