.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  max-width: 1064px;
  animation: modal 0.5s;
  z-index: 2;
}

@keyframes modal {
  from {
    left: 100vh;
  }
  to {
    left: 0;
  }
}

.contentContainer {
  margin: 0 auto;
  padding: 25px 40px;
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .contentContainer {
    padding: 25px;
  }
}
