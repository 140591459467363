.container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid black;
    padding-top: 15px;
    margin-top: 15px;
    padding-right: 40px
}

.container:hover {
    cursor: pointer;
}

.container:last-child {
    padding-bottom: 15px;
}

.elsSpan {
    display: flex;
    align-items: center;
}
