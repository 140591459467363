.commentContainer {
  border-bottom: 2px solid var(--grey8);
  padding: 10px 40px 0;
}

.userCommentContainer {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.userImageContainer {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.commentImageContainer {
  width: 32px;
  height: 32px;
  margin-right: 5px
}

.replyImageContainer {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.name {
  cursor: pointer;
}

.reportDeleteContainer {
  align-self: flex-start;
  position: absolute;
  right: 40px;
}

.commentText * {
  margin-bottom: 4px;
}

.reactionContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.likeContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 5px;
}
/* .likeContainer:hover {
  font-weight: bold;
} */
.likeIcon {
  margin-right: 5px;
}
.likeIcon:hover,
.commentsContainer:hover {
  opacity: 0.6;
}

.likeHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
  align-items: center;
} 

.likeHeaderContainer {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 2px solid var(--grey8);
  background: var(--magenta);
}

.likeHeaderTitle {
  font-family: 'OpenSans-Bold';
  font-size: 22px;
  line-height: 22px;
  color: var(--white);
}

.reactionContainer {
  display: block;
}

.reactionUserItemImgContainer {
  margin-right: 20px;
  width: 90px;
  height: 90px;
  position: relative;
  cursor: pointer;
}

.reactionUserItemImg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.reactionItemcontainer {
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
}

.reactionItemcontainer:hover,
.reactionItemcontainer:active {
  background-color: var(--grey5);
  cursor: pointer;
}

.reactionItemLabelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.likeIconWrapper {
  height: 24px;
}

@media only screen and (max-width: 768px) {
  .commentContainer {
    padding: 20px;
  }

  .reportDeleteContainer {
    right: 20px;
  }

  .userImageContainer {
    width: 70px;
    height: 70px;
  }
}
