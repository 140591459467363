.cardContainer {
    display: flex;
    height: 151px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;
    line-height: 1.2;
  }
  
  .cardCover {
    width: 151px !important;
    height: 151px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .siteTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--magenta);
    font-weight: bold;
  }
  
  .siteLink {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .siteDescription {
    color: var(--grey);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }
  
  .cancelButton {
    height: 20;
  }

  .fallbackIcon {
    height: 50%;
    width: 25%;
    align-self: center;
  }
  