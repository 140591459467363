.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(-363px);
  background-color: white;
  max-width: 1064px;
  animation: modal 0.5s;
  z-index: 2;
  cursor: default;
}

@keyframes modal {
  from {
    top: 100vh;
  }
  to {
    top: 0;
  }
}

.root {
  flex-grow: 1;
}

.menuButton {
  color: var(--white);
}

.title {
  color: var(--white);
  text-transform: capitalize;
  font-family: OpenSans-Bold;
  text-align: center;
}

.subTitle {
  color: var(--white);
  text-transform: capitalize;
  font-family: OpenSans-Bold;
  text-align: center;
  font-size: 0.75em; 
}

.toolbar {
  position: relative;
  justify-content: space-between;
  background-color: var(--magenta);
  align-items: center; 
}

.toolbar div {
  display: flex;
  flex-direction: column;
  width: 100%; 
  justify-content: center; 
}

.ELContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.profileSection {
  display: flex;
  justify-content: start;
  width: 80%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.profilePicture {
  width: 80px;
  height: 80px; 
  border-radius: 50%;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.formSection {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.formSection > *:not(:first-child) {
  margin-top: 20px;
}

.buttonSection {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px; 
}

.submitButton {
  background-color: var(--magenta);
  color: white;
  padding: 20px 150px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  text-transform: capitalize;
  font-family: OpenSans-Bold;
}

.bodyNoScroll {
  overflow: hidden;
}

.ELContainer{
  display: flex;
}

.nameContainer {
  width: '100%';
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space:pre-wrap;
}

.eagleLeaderText {
  font-size: 20px;
  font-style: italic;
}

.statusValue {
  margin-top: 5px;
}

.statusSection {
  margin-bottom: 20px;
}

.alertTitle {
  color: var(--magenta);
  margin-bottom: 5px;
  margin-top: 5px;
}

.completed {
  color: var(--green);
}

.pending{
  color: var(--orange);
}
