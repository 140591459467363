/* Registration Styling */

/* Global */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.headerContainer {
  width: 100%;
  background-color: var(--magenta);
  padding: 30px 0;
  text-align: center;
}
.contentContainer {
  margin: 0 auto;
  padding: 25px 40px;
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.radioForm {
  margin-top: 30px;
}
.buttonContainer {
  margin-top: 30px;
}
.dropdownWrapper {
  flex: 1;
}
.privacyPolicyContainer {
  padding: 0px 20%;
  text-align: center;
}
.clickable {
  cursor: pointer;
}
.centerText {
  text-align: center;
}
.clickable {
  cursor: pointer;
}
.displayNone {
  display: none;
}

/* Verify Email */
.verifyEmailContent {
  text-align: center;
  margin-top: 25px;
}
.verifyEmailContent p {
  margin-bottom: 25px;
}

/* Personal Information */
.autocompleteItems {
  padding: 5px 0;
}
h3,
.autocompleteItems p {
  display: inline;
}

/* Social Profile */
.userImageContainer {
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: 625px;
}

/* Military Status */
.ETSContainer {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.ETSdropdownWrapper {
  width: 30%;
}

.rankDropdownWrapper {
  margin-bottom: 40px;
}

/* Red Shirt */
.imageContainer {
  width: 100%;
  height: auto;
}

/* Get Red Shirt */
.pickerContainer {
  display: flex;
  justify-content: space-between;
}
.sizeChartBtn {
  position: relative;
  top: 22px;
  height: 27px;
  padding: 0 30px;
  cursor: pointer;
}
.paymentFormColumn {
  display: inline-block;
  width: 50%;
}
.paymentFormColumn:nth-of-type(1) {
  padding-right: 20px;
}
.paymentFormColumn:nth-of-type(2) {
  padding-left: 20px;
}
.radioButtonsContainer {
  display: flex;
  flex-direction: column;
}
.errorMessage {
  font-family: 'OpenSans-Bold';
  font-size: 12px;
  line-height: 11px;
  color: var(--magenta);
  text-transform: uppercase;
  margin-top: -10px;
  margin-bottom: 20px;
}
.link {
  cursor: pointer;
  color: var(--magenta);
  font-weight: 500;
}
.legalVaiwerStatusSwitch {
  margin: 20px 0 40px;
}

.emailMessage {
  padding-top: 20px;
}

.noEmail {
  text-align: center;
  margin-bottom: 10px;
}

.codeInput {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 80px;
  margin-left: 7px;
}

.codeInput[type=number] {
  color: var(--primary);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.codeInput:focus {
  outline-width: 0;
  border-bottom-color: var(--primary);
}
