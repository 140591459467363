.label {
  color: var(--grey);
  padding: 10px 40px;
}

.attendeeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey20);
}

.attendeeContainer:not(:last-child) {
  border-bottom: 1px solid var(--grey20);
}

@media only screen and (max-width: 768px) {
  .label {
    color: var(--grey);
    padding: 5px 20px;
  }
}
