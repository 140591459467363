.postLink {
    font-weight: bold;
    color: #0000EE;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.postLink:hover {
  cursor: pointer;
}