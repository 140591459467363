.optionsContainer {
  cursor: pointer;
  right: 0px;
  top: 0px;
}

.container {
  position: absolute;
  right: 0px;
  top: 32px;
  background-color: #f1f1f1;
  overflow: auto;
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.1);
  z-index: 1;
}

.container button {
  background-color: #f1f1f1;
  min-width: 160px;
  padding: 12px 16px;
  border-radius: 0;
  border: none;
  border-top: 1px solid var(--grey20);
  outline: none;
  color: var(--blue);
}

.dangerous {
  color: var(--magenta80) !important;
}

.container button:first-of-type {
  border-top: none;
}

.editContainer {
  position: absolute;
  right: 661px;
  z-index: 2;
}

.container button {
  cursor: pointer;
}
