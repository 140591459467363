/* Enhanced Card */

.container {
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--grey8);
  height: 123px; /* 1.5x the height of mobile */
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.imageContainer {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.eventTitle {
  font-family: 'ChangaOne-Italic';
  font-size: 24px;
  text-transform: uppercase;
  white-space: nowrap;
}
.cardImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.cardInfoContainer {
  flex: 1;
  margin-left: 10px;
}
.groupName {
  color: var(--magenta);
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
}
.dateInfo {
  color: var(--grey);
  font-family: 'OpenSans';
}
