.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: var(--white);
  box-shadow: none;
  padding: 30px;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
}

.xIconContainer {
  position: absolute;
  right: 6px;
  top: 6px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.xIconContainer:hover,
.xIconContainer:active {
  color: var(--grey);
  opacity: 0.6;
}
