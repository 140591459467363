.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(-363px);
  background-color: white;
  max-width: 1064px;
  animation: modal 0.5s;
  z-index: 2;
}

@keyframes modal {
  from {
    top: 100vh;
  }
  to {
    top: 0;
  }
}

.content {
  padding-bottom: 40px;
  overflow-y: scroll;
  max-height: calc(100vh - 128px);
}

.postContainer {
  border-bottom: 2px solid var(--grey8);
}

.namePostContainer {
  padding: 20px 100px 5px 100px;
}

.textImageContainer {
  margin-top: 20px;
}

.postImage {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  object-fit: cover;
}

.content textarea {
  width: 100%;
  max-width: 700;
  margin: 0 auto;
  padding: 0 100px;
}

.usersListContainer {
  width: 100%;
  max-width: 545px;
  margin: 0 auto;
  border: 1px solid var(--grey80);
  border-radius: 5px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.linkPreviewContainer {
  padding: 0 100px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .container {
    transform: translateX(0);
  }

  .namePostContainer {
    padding: 20px 20px 5px 20px;
  }

  .postImage {
    height: 200px;
  }

  .content textarea {
    padding: 0 20px;
  }
}
