    .container {
        width: 100vw;
        min-height: 100vh;
        height: 100vh;
        position: fixed;
        top: 0;
        transform: translateX(-363px);
        background-color: white;
        max-width: 1064px;
        animation: modal 0.5s;
        z-index: 2;
    }
    
    @keyframes modal {
        from {
        top: 100vh;
        }
        to {
        top: 0;
        }
    }

    .root {
        flex-grow: 1;
    }
    
    .icon{
        color: var(--grey80);
    }
    
    .toolbar {
        background-color: var(--magenta);
        height: 64px;
        position: relative;
        justify-content: space-between;
    }
    
    .menuButton {
        margin-right: 15px;
        color: var(--white);
    }
    
    .title {
        color: var(--white);
        text-transform: capitalize;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
    }

    .label {
        color: var(--grey80);
        padding-bottom: 2px;
        padding-bottom: 5px;
        padding-top: 10px;
    }
    
    .slider {
        color: var(--magenta);
        width: calc(100% - 40px);
        left: 20px;
    }
    
    .button {
        background-color: var(--magenta);
        color: var(--white);
        width: 70%;
        height: 50px;
    }
    
    .button:hover {
        background-color: var(--magenta); 
    }
    
    .reset {
        color: var(--white);
        text-transform: capitalize;
        font-weight: bold;
    }
    
    .tabsContainer {
        max-width: 625px;
        margin: 0 auto;
        padding: 2px 40px 0 40px;
    }
    
    .contentScroll {
        overflow-y: scroll;
        max-height: calc(100vh - 64px);
    }
    
    .contentContainer {
        width: 100%;
        max-width: 625px;
        margin: 0 auto;
    }
    
    .distanceLabel {
        margin-top: 20px;
        /* padding-top: 20px; */
        padding-bottom: 20px;
        color: var(--grey80);
    }
    
    .buttonContainer {
        margin-top: 40px;
    }
    
    .resetFilterContainer {
        padding-top: 25px;
    }
    
    .resetFilterText {
        color: var(--grey80);
        font-weight: 600;
        cursor: pointer;
    }
    
    .resetFilterText:hover {
        opacity: 0.6;
    }

    .toggleSwitch {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .sliderLabel{
        font-size: 14px;
    }

    .slider {
        display: inline-block;
        width: 75px;
        height: 41px;
        background-color: white;
        border: 1px solid var(--grey40);
        border-radius: 20px;
        transition: background-color 0.2s;
        margin-right: 20px;
    }
    
    /* The slider knob */
    .slider::before {
        position: absolute;
        content: "";
        height: 40px; 
        width: 40px; 
        left: 7px;
        bottom: .5px;
        background-color: var(--white);
        border: 1px solid var(--grey40);
        border-radius: 50%;
        box-sizing: border-box;
        transition: transform 0.2s;
    }
    
    /* The styling for when the checkbox is checked */
    input[type="checkbox"]:checked + .slider {
        border-color: var(--green);;
        background-color: #00ff0042;
    }
    
    input[type="checkbox"]:checked + .slider:before {
        transform: translateX(35px);
        background-color: var(--green);;
        border: 1px solid var(--green);;
    }
    
    /* Styling for the checkbox input itself to hide it visually but remain accessible */
    input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }
  
  /* Responsive */
  @media only screen and (max-width: 768px) {
    .container {
      transform: translateX(0);
    }
  }
