.labelContainer {
  padding-top: 20px;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition: opacity 2s ease-in;
}

.labelContainer:hover {
  opacity: 0.6;
}

.label {
  color: var(--grey80);
  padding-bottom: 2px;
}

.h2 {
  color: var(--grey80);
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.h2:hover,
.h2:active {
  color: var(--grey);
}

.selected,
.selected:hover {
  color: var(--magenta);
}
