.followButton {
    flex-grow: 1;
    flex-basis: 18%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--grey40);
    border: 1px solid var(--grey40);
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'OpenSans-Extrabold';
    font-size: 18px;
  }

  .smallFollowButton{
    flex-grow: 1;
    flex-basis: 18%;
    height: 50px;
    width: 150px;
    max-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--grey40);
    border: 1px solid var(--grey40);
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'OpenSans-Extrabold';
    font-size: 18px;
  }

  .followButtonFollowed {
    color: var(--white);
    background-color: var(--magenta);
  }
  
  .followButton:active,
  .followButton:hover,
  .editButton:hover,
  .editButton:active {
    cursor: pointer;
    opacity: 0.6;
  }
  
  .followButtonClicked {
    background-color: var(--magenta);
    color: white;
  }
  
  .followButtonClicked:hover {
    background-color: #ad234a;
  }
  
  .followButtonClicked:active {
    background-color: var(--magenta);
  }
