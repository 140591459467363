.contHolder {
  position: sticky;
  width: 100%;
  bottom: 0px;
}

.container {
  width: 100%;
  max-width: 701px;
  height: 70px;
  background-color: var(--magenta);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  position: fixed;
  grid-column: 1/3;
}

.commentInput {
  background-color: var(--white);
  width: 100%;
  height: 70%;
  margin: 20px;
  border-radius: 24px;
  border-color: white;
  padding-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-column: 1/3;
}

.commentInput p {
  font-size: 16px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .container {
    bottom: 94px;
  }
}
