.container {
  border-color: var(--grey8);
  position: relative;
  padding-left: 363px;
  height: 100%;
}

.searchContainer {
  width: 100%;
  padding: 25px 40px;
  background: var(--magenta);
  display: flex;
  align-items: center;
}

.notificationsButton {
  outline: none;
  border: none;
  padding: 0px;
  background: #fff;
  height: 48px;
  min-width: 48px;
  border-radius: 24px;
  margin-left: 12px;
  cursor: pointer;
}

.emptyListText {
  padding: 40px 40px 0;
  height: calc(100vh - 160px);
}

.aggregateFeedContainter {
  min-height: calc(100vh - 160px);
  height: 100%;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .container {
    padding-left: 0;
    padding-bottom: 22px;
  }

  .searchContainer {
    padding: 25px;
  }
}
