.container {
  margin-bottom: 20px;
}

.button {
  position: relative !important;
  padding: 6px 0 !important;
  justify-content: space-between !important;
  width: 100%;
}

.selectedValue {
  position: relative;
  font-size: 13px;
  left: 2px;
}

.menu div:nth-of-type(3) {
  max-width: 621px !important;
  width: calc(100% - 80px) !important;
}
